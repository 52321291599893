import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const OnScrollAnimation = ({ children }) => {
	const controls = useAnimation();
	const [ref, inView] = useInView({threshold: .3});

	useEffect(() => {
		if (inView) controls.start("visible");
	}, [controls, inView]);

	return (
		<motion.div
			ref={ref}
			animate={controls}
			initial="hidden"
			transition={{ duration: .5 }}
			variants={{
			visible: { position: "relative", opacity: 1},
			hidden: { position: "relative", opacity: 0}
			}}
		>
			{children}
		</motion.div>
	);
}

export default OnScrollAnimation;