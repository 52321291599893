import Header from '../../organisms/Header/Header';
import Footer from '../../organisms/Footer/Footer';
import HomeTemplate from '../../templates/Home/Home';

const Home = () => {
	return (
		<>
			<Header />
			<HomeTemplate />
			<Footer />
		</>
	);
}

export default Home;