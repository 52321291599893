import OnScrollAnimation from '../../utils/OnScrollAnimation';

// graphics
import mail from './img/mail.svg';

// styles
import './Contacts.scss';

const Contacts = () => {
	let contactBlocks = [
		{
			type: "email",
			heading: "По любым вопросам",
			text: "hello@easy2book.ru"
		},
		{
			type: "email",
			heading: "Тех. поддержка",
			text: "support@easy2book.ru"
		}
	];

	return (
		<div className="Contacts pt-7 pt-xxl-8">
			<OnScrollAnimation>
			<div className="container py-5 pt-md-6 pb-lg-8">
				<div className="row">
					<h1 className="col-12 text-lg-center">Контакты</h1>
				</div>
				<div className="row justify-content-center mt-6">
					<div className="col-md-8 col-lg-6 col-xl-5 col-xxl-4">
						<p className="text-lg-center pb-2">Напишите нам, мы всегда рады помочь</p>
						{contactBlocks.map((item, key) =>
							<div key={key} className="contact-block p-2 p-md-3 my-2 my-md-3 d-flex align-items-center align-content-center">
								<img src={mail} className="contact-block__icon p-1 p-md-2" alt="" />
								<div className="pl-2 pl-md-3">
									<h4 className="mb-0">{item.heading}</h4>
									<span>{item.text}</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			</OnScrollAnimation>

		</div>
	);
}

export default Contacts;