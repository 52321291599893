import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function ScrollToAnchor() {
	const history = useHistory();
	const location = useLocation();

	function findPos(obj) {
	    let curtop = 0;
	    if (obj.offsetParent) {
	        do {
	            curtop += obj.offsetTop;
	        } while (obj = obj.offsetParent);
	    return curtop;
	    }
	}
	useEffect(() => {
		let el = location.hash ? document.getElementById(location.hash.substr(1)) : null;
		if (el) {
			let headerHeight = document.querySelector(".Header").offsetHeight;
			window.scroll(0,findPos(el)-headerHeight);
			history.replace(location.path);
		}
	}, [location.hash])

	return null;
}