import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './pages/Home/Home';
import Tariffs from './pages/Tariffs/Tariffs';
import Agreement from './pages/Agreement/Agreement';
import Contacts from './pages/Contacts/Contacts';
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';

import ScrollToTop from './utils/ScrollToTop';
import ScrollToAnchor from './utils/ScrollToAnchor';

// styles
import './utils/bootstrap/bootstrap.scss';
import './utils/fonts/Gilroy/stylesheet.css';
import './utils/fonts/Lato/stylesheet.css';
import './App.scss';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>

          <Route path="/tariffs">
            <Tariffs />
          </Route>

          <Route path="/agreement">
            <Agreement />
          </Route>

          <Route path="/contacts">
            <Contacts />
          </Route>

          <Route path="/signin">
            <SignIn />
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>

          <Route path="/forget">
            <ForgetPassword />
          </Route>

          <Route path="/">
            <Home />
          </Route>

        </Switch>
        <ScrollToTop />
        <ScrollToAnchor />
      </Router>
    </div>
  );
}

export default App;
