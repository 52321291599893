import PhoneInput from 'react-phone-input-2'

// styles
import 'react-phone-input-2/lib/bootstrap.css'
import './InputPhone.scss';

const InputPhone = (props) => {
	/*
	props.label
	props.name
	props.onChange
	props.onBlur
	props.value
	props.error
	*/

	return (
		<label className={"InputPhone d-block "+(props.error ? "error" : "")}>
			<span className="d-block">{props.label}</span>
			<PhoneInput country={'ru'} type="text" inputProps={{name: props.name}} onChange={props.onChange} onBlur={props.onBlur} value={props.value} />
			{props.error ? <span className="InputPhone__error-text d-block">{props.error}</span> : ""}
			{props.hint ? <span className="InputPhone__hint-text d-block">{props.hint}</span> : ""}
		</label>
	);
}

export default InputPhone;