import HeaderAuth from '../../organisms/HeaderAuth/HeaderAuth';
import ForgetPasswordTemplate from '../../templates/ForgetPassword/ForgetPassword';

const ForgetPassword = () => {
	return (
		<>
			<HeaderAuth />
			<ForgetPasswordTemplate />
		</>
	);
}

export default ForgetPassword;