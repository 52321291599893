import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from "../../utils/API";

import FormStepSecurity from './FormStepSecurity';
import FormStepUser from './FormStepUser';
import FormStepBusiness from './FormStepBusiness';

// graphics
import LockIcon from './img/LockIcon';
import UserIcon from './img/UserIcon';
import BusinessIcon from './img/BusinessIcon';

// styles
import './SignUp.scss';

const SignUp = () => {
	let [currentFormStep, setCurrentFormStep] = useState(0);
	let [formStepsData, setFormStepsData] = useState({});

	let handleStepSubmit = (endedStep, data) => {
		let actualData = {...formStepsData, ...data};
		setFormStepsData(actualData);
		if(endedStep === 2) return sendRequest(actualData);
		setCurrentFormStep(endedStep+1);
	}

	// POST SIGNIN
	let postSignUp = async (data) => {
		let res = await API.post('/users', data);
		return await res.data;
	};
	// END OF POST SIGNIN

	let sendRequest = (actualData) => {
		postSignUp(actualData).then((result) => {
			if(!result.success) return alert("Сервер недоступен. Попробуйте позже");
			setCurrentFormStep(3);
		},
		(error) => {
			alert("Сервер недоступен. Попробуйте позже");
		})
	}

	let goToStep = (step) => {
		if(step >= currentFormStep) return false;
		setCurrentFormStep(step);
	}

	return (
		<div className="SignUp">

			<div className="container py-5">
				<div className="row justify-content-center">
					<div className="col-xl-10 signup-block p-4 p-xl-5">
						<div className="row d-block">
							<div className="m-auto col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center pb-4">
								<h3 className="mb-2 text-center">Регистрация бизнеса</h3>
								<p>Отправьте заявку на подключение к сервису. С вами свяжется менеджер для подтверждения.</p>
							</div>
							{currentFormStep !== 3 ? <div className="col-md-9 col-lg-7 col-xl-6 col-xxl-5 mx-auto pt-2 mb-4">
								<div className="sign-up-progress d-flex justify-content-between">
									<div onClick={() => goToStep(0)} className={"sign-up-progress__item p-2 "+(currentFormStep === 0 ? "current" : "")}><LockIcon /></div>
									<div onClick={() => goToStep(1)} className={"sign-up-progress__item p-2 "+(currentFormStep === 1 ? "current" : "")}><UserIcon /></div>
									<div onClick={() => goToStep(2)} className={"sign-up-progress__item p-2 "+(currentFormStep === 2 ? "current" : "")}><BusinessIcon /></div>
								</div>
							</div> : "" }
							<div className="mx-auto col-md-9 col-lg-7 col-xl-6 col-xxl-5">
								{<div className={currentFormStep === 0 ? "" : "d-none"}> <FormStepSecurity onSubmit={handleStepSubmit} /> </div>}
								{<div className={currentFormStep === 1 ? "" : "d-none"}><FormStepUser onSubmit={handleStepSubmit} /> </div>}
								{<div className={currentFormStep === 2 ? "" : "d-none"}><FormStepBusiness onSubmit={handleStepSubmit} /> </div>}
								{currentFormStep === 3 && <div className="thank-you text-center py-3 px-1 px-md-2">
									<h4 className="mb-2">Спасибо!</h4>
									<p className="mb-2">Ваша заявка успешно отправлена. В ближайшее время с вами свяжется менеджер для уточнения деталей</p>
									<Link to="/" className="btn btn-primary">На главную</Link>
								</div> }
							</div>
						</div>
					</div>
				</div>
				<div className="row justify-content-center mt-3">
					<span>Уже есть аккаунт? <Link to="/signin">Войти</Link></span>
				</div>
			</div>

		</div>
	);
}

export default SignUp;