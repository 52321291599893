import Header from '../../organisms/Header/Header';
import Footer from '../../organisms/Footer/Footer';
import AgreementTemplate from '../../templates/Agreement/Agreement';

const Agreement = () => {
	return (
		<>
			<Header />
			<AgreementTemplate />
			<Footer />
		</>
	);
}

export default Agreement;