import { Link } from 'react-router-dom';

// graphics
import logotype from './logotype.svg';

// styles
import './HeaderAuth.scss';

const HeaderAuth = () => {
	return (
		<div className="HeaderAuth">
			<div className="container py-2 py-md-3 py-xxl-4">
				<div className="row">
					<div className="HeaderAuth__logo col-12 text-center">
						<Link to="/"><img src={logotype} alt="Логотип Easy2Book" /></Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HeaderAuth;