// styles
import './Input.scss';

const Input = (props) => {
	/*
	props.label
	props.name
	props.hint
	props.type
	props.onChange
	props.onBlur
	props.value
	props.error
	*/

	return (
		<label className="Input d-block">
			<span className="d-block">{props.label}</span>
			<input className={"w-100 form-control "+(props.error ? "error" : "")} name={props.name} type={props.type || "text"} onChange={props.onChange} onBlur={props.onBlur} value={props.value} />
			{props.error ? <span className="Input__error-text d-block">{props.error}</span> : ""}
			{props.hint ? <span className="Input__hint-text d-block">{props.hint}</span> : ""}
		</label>
	);
}

export default Input;