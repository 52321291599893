import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../atoms/Input/Input';

const FormStepBusiness = (props) => {
	/*
	props.onSubmit
	*/

	let initialValues = { companyName: '', companyDirections: '' };

	let onSubmit = (values, { setSubmitting }) => {
		setSubmitting(false);
		props.onSubmit(2, values);
	}

	let validationSchema = Yup.object({
		companyName: Yup.string().required('Обязательно'),
		companyDirections: Yup.string().required('Обязательно'),
	})

	return (
     	<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
       		{({values, errors, touched, handleChange, handleBlur, isSubmitting}) => (
       			<Form className="">
					<div className="mb-2">
						<Input label="Название компании *" name="companyName" onChange={handleChange} onBlur={handleBlur} value={values.companyName} error={errors.companyName && touched.companyName && errors.companyName} />
					</div>
					<div className="mb-4">
						<Input label="Направления *" name="companyDirections" onChange={handleChange} onBlur={handleBlur} value={values.companyDirections} error={errors.companyDirections && touched.companyDirections && errors.companyDirections} />
					</div>
					<button disabled={isSubmitting} type="submit" className="btn btn-primary w-100">Отправить заявку</button>
					<span className="agreement text-center d-block mt-1">Нажимая на “Отправить запрос”, я даю своё согласие на обработку моих персональных данных</span>
				</Form>
			)}
     	</Formik>
	);
}

export default FormStepBusiness;