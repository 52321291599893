import Header from '../../organisms/Header/Header';
import Footer from '../../organisms/Footer/Footer';
import ContactsTemplate from '../../templates/Contacts/Contacts';

const Contacts = () => {
	return (
		<>
			<Header />
			<ContactsTemplate />
			<Footer />
		</>
	);
}

export default Contacts;