import Header from '../../organisms/Header/Header';
import Footer from '../../organisms/Footer/Footer';
import TariffsTemplate from '../../templates/Tariffs/Tariffs';

const Tariffs = () => {
	return (
		<>
			<Header />
			<TariffsTemplate />
			<Footer />
		</>
	);
}

export default Tariffs;