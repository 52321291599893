const Icon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M4.16699 10.0002C3.70675 10.0002 3.33366 10.3733 3.33366 10.8335V16.6668C3.33366 17.1271 3.70675 17.5002 4.16699 17.5002H15.8337C16.2939 17.5002 16.667 17.1271 16.667 16.6668V10.8335C16.667 10.3733 16.2939 10.0002 15.8337 10.0002H4.16699ZM1.66699 10.8335C1.66699 9.45278 2.78628 8.3335 4.16699 8.3335H15.8337C17.2144 8.3335 18.3337 9.45278 18.3337 10.8335V16.6668C18.3337 18.0475 17.2144 19.1668 15.8337 19.1668H4.16699C2.78628 19.1668 1.66699 18.0475 1.66699 16.6668V10.8335Z" fill="#03050A"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M10 2.50016C9.11594 2.50016 8.2681 2.85135 7.64298 3.47647C7.01786 4.10159 6.66667 4.94944 6.66667 5.8335V9.16683C6.66667 9.62707 6.29357 10.0002 5.83333 10.0002C5.3731 10.0002 5 9.62707 5 9.16683V5.8335C5 4.50741 5.52678 3.23564 6.46447 2.29796C7.40215 1.36028 8.67392 0.833496 10 0.833496C11.3261 0.833496 12.5979 1.36028 13.5355 2.29796C14.4732 3.23564 15 4.50741 15 5.8335V9.16683C15 9.62707 14.6269 10.0002 14.1667 10.0002C13.7064 10.0002 13.3333 9.62707 13.3333 9.16683V5.8335C13.3333 4.94944 12.9821 4.10159 12.357 3.47647C11.7319 2.85135 10.8841 2.50016 10 2.50016Z" fill="#03050A"/>
		</svg>
	);
}

export default Icon;
