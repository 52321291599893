const Agreement = () => {
	return (
		<div className="Agreement pt-7 pt-xxl-8">

			<div className="container py-5 pt-md-6 pb-lg-8">
				<div className="row">
					<h1 className="col-12 text-lg-center">Пользовательское соглашение</h1>
				</div>
				<div className="row justify-content-center mt-6">
					<div className="col-lg-10">
						<p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем (далее Easy2Book или Администрация) с одной стороны и пользователем сайта с другой. Используя сайт, Вы соглашаетесь с условиями данного соглашения.</p>
						<h2 className="display-2">Предмет соглашения</h2>
						<h3 className="display-3">Администрация предоставляет пользователю право на размещение на сайте следующей информации:</h3>
						<ul>
							<li>Текстовой информации</li>
							<li>Фотоматериалов</li>
						</ul>

						<h2 className="display-2">Права и обязанности сторон</h2>
						<h3 className="display-3">Пользователь имеет право:</h3>
						<ul>
							<li>осуществлять поиск информации на сайте</li>
							<li>получать информацию на сайте</li>
							<li>создавать информацию для сайта</li>
							<li>использовать информацию сайта в личных некоммерческих целях</li>
						</ul>
						<h3 className="display-3">Администрация имеет право:</h3>
						<ul>
							<li>по своему усмотрению и необходимости создавать, изменять, отменять правила</li>
							<li>ограничивать доступ к любой информации на сайте</li>
						</ul>
						<h3 className="display-3">Пользователь обязуется:</h3>
						<ul>
							<li>обеспечить достоверность предоставляемой информации</li>
							<li>обеспечивать сохранность личных данных от доступа третьих лиц</li>
							<li>не нарушать работоспособность сайта</li>
							<li>не совершать действия, направленные на введение других Пользователей в заблуждение</li>
							<li>не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам</li>
							<li>не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ</li>
							<li>не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ</li>
						</ul>
						<h3 className="display-3">Администрация обязуется:</h3>
						<ul>
							<li>поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам</li>
						</ul>

						<h2 className="display-2">Ответственность сторон</h2>
						<ul>
							<li>пользователь лично несет полную ответственность за распространяемую им информацию</li>
							<li>администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</li>
							<li>в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса</li>
						</ul>

						<h2 className="display-2">Условия действия Соглашения</h2>
						<p>Данное Соглашение вступает в силу при регистрации на сайте. Соглашение перестает действовать при появлении его новой версии. Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению. Администрация не оповещает пользователей об изменении в Соглашении.</p>
					</div>
				</div>
			</div>

		</div>
	);
}

export default Agreement;