import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../atoms/Input/Input';
import API from "../../utils/API";

// styles
import './ForgetPassword.scss';

const ForgetPassword = () => {
	let [requestResult, setRequestResult] = useState(false);

	let initialValues = { email: '' };

	// POST FORGET
	let postForget = async (data) => {
		let res = await API.post('/password', data);
		return await res.data;
	};
	// END OF POST FORGET

	let signInHandler = (values, { setSubmitting }) => {
		postForget(values).then((result) => {
			setSubmitting(false);
			if(!result.success) return alert("Сервер недоступен. Попробуйте позже");
			setRequestResult(true);
		},
		(error) => {
			alert("Сервер недоступен. Попробуйте позже");
			setSubmitting(false);
		})
	}

	let validationSchema = Yup.object({
		email: Yup.string().email('Введите корректный email').required('Обязательно'),
	})

	return (
		<div className="ForgetPassword">

			<div className="container py-5">
				<div className="row justify-content-center">
				
     				<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={signInHandler}>
       					{({values, errors, touched, handleChange, handleBlur, isSubmitting}) => (
         					<Form className="forget-password-block col-md-8 col-lg-6 col-xl-5 col-xxl-4 p-4">
								<h3 className="mb-4 text-center">Восстановление пароля</h3>
								{!requestResult ? <>
									<div className="pt-2 mb-4">
										<Input label="Email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} error={errors.email && touched.email && errors.email} />
									</div>
									<button disabled={isSubmitting} type="submit" className="btn btn-primary w-100">Продолжить</button>
								</> : <div className="thank-you text-center py-3 px-1 px-md-2">
									<h4 className="mb-2">Всё успешно!</h4>
									<p className="mb-2">Мы направили email-письмо с инструкцией по восстановлению пароля для аккаунта, если такой аккаунт существует</p>
									<Link to="/" className="btn btn-primary">На главную</Link>
								</div> }
							</Form>
						)}
     				</Formik>
				</div>
				<div className="row justify-content-center mt-3">
					<Link to="/signin">Назад к входу в аккаунт</Link>
				</div>
			</div>

		</div>
	);
}

export default ForgetPassword;