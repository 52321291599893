import HeaderAuth from '../../organisms/HeaderAuth/HeaderAuth';
import SignUpTemplate from '../../templates/SignUp/SignUp';

const SignUp = () => {
	return (
		<>
			<HeaderAuth />
			<SignUpTemplate />
		</>
	);
}

export default SignUp;