import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../atoms/Input/Input';
import InputPassword from '../../atoms/InputPassword/InputPassword';
import API from "../../utils/API";

// styles
import './SignIn.scss';

const SignIn = () => {
	let initialValues = { email: '', password: '' };

	// POST SIGNIN
	let postSignIn = async (data) => {
		let res = await API.post('/login', data);
		return await res.data;
	};
	// END OF POST SIGNIN

	let signInHandler = (values, { setSubmitting, setErrors }) => {
		postSignIn(values).then((result) => {
			setSubmitting(false);
			if(!result.success && result.activated === false) return setErrors({"email": 'Ваш аккаунт ещё не активирован'});
			if(!result.success) return setErrors({"password": 'Email или пароль введён неверно'});
		},
		(error) => {
			alert("Сервер недоступен. Попробуйте позже");
			setSubmitting(false);
		})
	}

	let validationSchema = Yup.object({
		password: Yup.string().required('Обязательно'),
		email: Yup.string().email('Введите корректный email').required('Обязательно'),
	})

	return (
		<div className="SignIn">

			<div className="container py-5">
				<div className="row justify-content-center">
				
     				<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={signInHandler}>
       					{({values, errors, touched, handleChange, handleBlur, isSubmitting}) => (
         					<Form className="signin-block col-md-8 col-lg-6 col-xl-5 col-xxl-4 p-4">
								<h3 className="mb-5 text-center">Добро пожаловать</h3>
								<div className="mb-2">
									<Input label="Email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} error={errors.email && touched.email && errors.email} />
								</div>
								<div className="mb-2">
									<InputPassword label="Пароль" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password} error={errors.password && touched.password && errors.password} />
								</div>
								<div className="text-center mb-4">
									<span>Забыли пароль? <Link to="/forget" className="ml-md-1 d-block d-md-inline-block">Восстановить пароль</Link></span>
								</div>
								<button disabled={isSubmitting} type="submit" className="btn btn-primary w-100">Войти</button>
							</Form>
						)}
     				</Formik>
				</div>
				<div className="row justify-content-center mt-3">
					<span>Ещё нет аккаунта? <Link to="/signup">Создать аккаунт</Link></span>
				</div>
			</div>

		</div>
	);
}

export default SignIn;