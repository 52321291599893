import { useState } from 'react';
import { Link } from 'react-router-dom';
import OnScrollAnimation from '../../utils/OnScrollAnimation';

// styles
import './Tariffs.scss';

const Tariffs = () => {
	let [currentCountKey, setCurrentCountKey] = useState(0);

	let periods = [
		{
			name: "per year",
			label: "1 год",
			monthsCount: 12,
			bonusMonths: 3,
		},
		{
			name: "per 6 months",
			label: "6 месяцев",
			monthsCount: 6,
			bonusMonths: 1,
		},
		{
			name: "per 3 months",
			label: "3 месяца",
			monthsCount: 3,
			bonusMonths: 0,
		},
	];
	let tariffs = [
		{
			label: "До 5",
			id: "before5",
			initalMonthPrice: 1600
		},
		{
			label: "До 10",
			id: "before10",
			initalMonthPrice: 1800
		},
		{
			label: "До 20",
			id: "before20",
			initalMonthPrice: 2000
		},
		{
			label: "Без ограничений",
			id: "no-restrictions",
			initalMonthPrice: 4000
		},
	];

	let calculationOfMonthlyPayment = (initalMonthPrice, monthsCount, bonusMonths) => {
		let monthlyPayment = initalMonthPrice*monthsCount/(monthsCount+bonusMonths);
		return Math.round(monthlyPayment);
	}

	let calculateOldPrice = (initalMonthPrice, monthsCount, bonusMonths) => {
		let oldPrice = initalMonthPrice*(monthsCount+bonusMonths);
		return Math.round(oldPrice);
	}

	let calculateCurrentPrice = (initalMonthPrice, monthsCount, bonusMonths) => {
		let monthlyPayment = initalMonthPrice*monthsCount/(monthsCount+bonusMonths);
		let currentPrice = monthlyPayment*monthsCount;
		return Math.round(currentPrice);
	}

	let declOfNum = (number, titles) => {  
    	let cases = [2, 0, 1, 1, 1, 2];  
    	return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
	}

	let includedFeatures = [
		"Персональная веб-страница для бронирования",
		"Онлайн-запись 24/7",
		"Статистика и аналитика бронирований, работы специалистов, филиалов",
		"Мгновенные оповещения о бронированиях",
		"Управление филиалами",
		"Расчет зарплаты сотрудников",
		"Электронный календарь и журнал",
		"Доступ к управлению с любого устройства",
	];

	return (
		<div className="Tariffs pt-7 pt-xxl-8">

			<OnScrollAnimation>
			<div className="opportunities container py-5 py-md-6">
				<div className="row">
					<h1 className="col-12 text-lg-center">Тарифы</h1>
				</div>
				<div className="row justify-content-between mt-6">
					<div className="col-md-auto">
						<span className="mb-1 mb-md-2 d-block">Сколько у вас сотрудников?</span>
						<form className="change">
							{tariffs.map((item, key) =>
								<div key={key} className="d-md-inline-block">
									<input onChange={() => setCurrentCountKey(key)} id={item.id} name="count" type="radio" checked={currentCountKey === key} />
									<label className="w-100 py-2 px-3 px-md-4 mb-0" htmlFor={item.id}>{item.label}</label>
								</div>
							)}
						</form>
					</div>
				</div>
				<div className="tarriffs-container mt-5">
					{tariffs.map((item, key) =>
						<div key={key} className="row">
							{key === currentCountKey ? periods.map((periodData, periodKey) =>
								<div key={periodKey} className="tariff-card mb-4 mb-xl-0 col-md-6 col-xl-4">
									<div className="tariff-card-inner p-3 p-lg-4 h-100 d-flex flex-column justify-content-between">
										<div className="tariff-card__period">
											<h3>{periodData.label}</h3>
											<span className={!periodData.bonusMonths ? "invisible" : ""}>+{periodData.bonusMonths} {declOfNum(periodData.bonusMonths, ["месяц", "месяца", "месяцев"])} в подарок</span>
										</div>
										<div>
											<h2 className="mt-4 mt-md-5 mb-3 mb-md-4">{calculationOfMonthlyPayment(item.initalMonthPrice, periodData.monthsCount, periodData.bonusMonths)} ₽/мес</h2>
											<div>
												<span className={"tariff-card__old-price "+ (!periodData.bonusMonths ? "invisible" : "")}>{calculateOldPrice(item.initalMonthPrice, periodData.monthsCount, periodData.bonusMonths)} руб.</span>
												<span className="d-block">{calculateCurrentPrice(item.initalMonthPrice, periodData.monthsCount, periodData.bonusMonths)} руб. за {periodData.monthsCount+periodData.bonusMonths} {declOfNum(periodData.monthsCount+periodData.bonusMonths, ["месяц", "месяца", "месяцев"])} </span>
											</div>
											<Link to="/signup" className="btn btn-primary w-100 mt-3 mt-md-4">Попробовать бесплатно</Link>
											<span className="text-center d-block mt-1 mt-md-2">7 дней бесплатный период</span>
										</div>
									</div>
								</div>
							) : ""}
						</div>
					)}
				</div>
			</div>
			</OnScrollAnimation>

			<OnScrollAnimation>
			<div className="included-features container pt-5 pb-4 py-lg-7 mb-lg-4">
				<div className="row justify-content-center mb-2 mb-md-3">
					<h2>Что вы получаете?</h2>
				</div>
				<ul className="features-list row mb-lg-1">
					{includedFeatures.map((item, key) => 
						<li key={key} className="features-list__item col-md-6 col-xl-4 my-2 my-md-3">{item}</li>
					)}
				</ul>
			</div>
			</OnScrollAnimation>

		</div>
	);
}

export default Tariffs;