import { useState } from 'react';

// graphics
import eyeOpened from './img/eye-opened.svg';
import eyeClosed from './img/eye-closed.svg';

// styles
import './InputPassword.scss';

const InputPassword = (props) => {
	/*
	props.label
	props.name
	props.hint
	props.onChange
	props.onBlur
	props.value
	props.error
	*/

	let [isPasswordVisible, setIsPasswordVisible] = useState(false);
	
	return (
		<label className="InputPassword d-block">
			<span className="d-block">{props.label}</span>
			<div className="d-flex align-items-center">
				<input className={"w-100 form-control "+(props.error ? "error" : "")} name={props.name} type={isPasswordVisible ? "text" : "password"} onChange={props.onChange} onBlur={props.onBlur} value={props.value} />
				<img className="eye mr-2 mr-xl-3" src={isPasswordVisible ? eyeClosed : eyeOpened} onClick={() => setIsPasswordVisible(!isPasswordVisible)} alt="show/hide" />
			</div>
			{props.error ? <span className="InputPassword__error-text d-block">{props.error}</span> : ""}
			{props.hint ? <span className="InputPassword__hint-text d-block">{props.hint}</span> : ""}
		</label>
	);
}

export default InputPassword;