import HeaderAuth from '../../organisms/HeaderAuth/HeaderAuth';
import SignInTemplate from '../../templates/SignIn/SignIn';

const SignIn = () => {
	return (
		<>
			<HeaderAuth />
			<SignInTemplate />
		</>
	);
}

export default SignIn;