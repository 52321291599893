import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../atoms/Input/Input';
import InputPassword from '../../atoms/InputPassword/InputPassword';

const FormStepSecurity = (props) => {
	/*
	props.onSubmit
	*/

	let initialValues = { email: '', password: '' };

	let onSubmit = (values, { setSubmitting }) => {
		setSubmitting(false);
		props.onSubmit(0, values);
	}

	let validationSchema = Yup.object({
		password: Yup.string()
			.min(8, 'Введите 8 символов или больше')
			.required('Обязательно'),
		email: Yup.string().email('Введите корректный email').required('Обязательно'),
	})

	return (
     	<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
       		{({values, errors, touched, handleChange, handleBlur, isSubmitting}) => (
       			<Form className="">
					<div className="mb-2">
						<Input label="Email *" name="email" hint="Этот email будет использоваться для входа" onChange={handleChange} onBlur={handleBlur} value={values.email} error={errors.email && touched.email && errors.email} />
					</div>
					<div className="mb-4">
						<InputPassword label="Пароль *" name="password" hint="Минимум 8 символов" onChange={handleChange} onBlur={handleBlur} value={values.password} error={errors.password && touched.password && errors.password} />
					</div>
					<button disabled={isSubmitting} type="submit" className="btn btn-primary w-100">Продолжить</button>
				</Form>
			)}
     	</Formik>
	);
}

export default FormStepSecurity;