import { Link } from 'react-router-dom';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import OnScrollAnimation from '../../utils/OnScrollAnimation';

// graphics
import mainIllustration from './img/main-illustration.svg';
import easyToStart from './img/advantages/easy-to-start.svg';
import easyToUse from './img/advantages/easy-to-use.svg';
import interestingToImprove from './img/advantages/interesting-to-improve.svg';
import onlineBooking from './img/online-booking.png';
import adminDashboard from './img/admin-dashboard.png';
import opportunitiesBarChart from './img/opportunities/bar-chart.svg';
import opportunitiesCheckSquare from './img/opportunities/check-square.svg';
import opportunitiesGlobal from './img/opportunities/globe.svg';
import opportunitiesShare from './img/opportunities/share.svg';
import opportunitiesSidebar from './img/opportunities/sidebar.svg';
import opportunitiesUsers from './img/opportunities/users.svg';
import areasBeauty from './img/areas/beauty.png';
import areasGum from './img/areas/gum.png';
import areasMedicine from './img/areas/medicine.png';
import areasLeisure from './img/areas/leisure.png';
import areasAuto from './img/areas/cars.png';

// styles
import 'swiper/swiper.scss';
import './Home.scss';

SwiperCore.use([Autoplay]);

const Home = () => {
	let opportunities = [
		{
			icon: opportunitiesCheckSquare,
			heading: "Онлайн-запись 24/7",
			text: "Не упустите ни одного клиента, который захотел записаться в нерабочее для компании время"
		},
		{
			icon: opportunitiesGlobal,
			heading: "Онлайн веб-страница",
			text: "Не тратьте время и силы на создание отдельного сайта. Персональная страница с этим справится"
		},
		{
			icon: opportunitiesShare,
			heading: "Делитесь в соц. медиа",
			text: "Оставляйте ссылку на персональную страницу в социальных сетях, СМС-рассылках и в любом другом месте"
		},
		{
			icon: opportunitiesBarChart,
			heading: "Статистика и аналитика",
			text: "Анализируйте работу вашего бизнеса: от клиентов до популярных услуг"
		},
		{
			icon: opportunitiesSidebar,
			heading: "Календарь и журнал",
			text: "Вся информациях о клиентах и предстоящих записях в одном месте"
		},
		{
			icon: opportunitiesUsers,
			heading: "Клиентская база",
			text: "Быстрый поиск по клиентам, учёт, история посещений и индивдуальные комментарии"
		},
	];

	let areas = [
		{
			image: areasBeauty,
			heading: "Красота"
		},
		{
			image: areasGum,
			heading: "Спорт и фитнес"
		},
		{
			image: areasMedicine,
			heading: "Медицина"
		},
		{
			image: areasLeisure,
			heading: "Досуг"
		},
		{
			image: areasAuto,
			heading: "Авто"
		},
	];

	return (
		<div className="Home pt-7 pt-xxl-8">

			<OnScrollAnimation>
			<div className="main container d-flex align-items-center align-content-center">
				<div className="row py-5 py-md-7 align-items-center align-content-center">
					<div className="col-lg-8">
						<h1 className="main__title display-1 mb-4 mb-md-5">Сервис онлайн-записи и автоматизации ваших бизнес-процессов</h1>
						<Link to="/signup" className="btn btn-primary mb-3 mb-md-4">Попробовать бесплатно</Link>
						<span className="d-block">7 дней <Link to="/tariffs" className="ml-md-1 d-block d-md-inline-block">Подробнее про тарифы</Link></span>
					</div>
					<div className="main__illustration d-none d-lg-block col-lg-6 col-xl-4">
						<img className="w-100" src={mainIllustration} alt="easy2book сервис" />
					</div>
				</div>
			</div>
			</OnScrollAnimation>

			<OnScrollAnimation>
			<div className="online-booking container py-5 py-lg-8">
				<div className="row justify-content-between align-items-center align-content-center">
					<div className="col-lg-6 order-md-1">
						<h2 className="mb-4 mb-md-5">Привлекайте новых клиентов с новым форматом онлайн—записи</h2>
						<p>У вашего бизнеса появится персональная страница для бронирования. Используйте ссылку на неё в социальных сетях, мессенджерах или картах. Вы можете выбрать дизайн и адаптировать её под фирменный стиль компании</p>
					</div>
					<div className="col-md-8 col-lg-5 mt-4 mt-md-0 mb-md-5 mb-lg-0">
						<img className="w-100" src={onlineBooking} alt="easy2book сервис" />
					</div>
				</div>
			</div>
			</OnScrollAnimation>

			<OnScrollAnimation>
			<div className="advantages container py-6">
				<div className="advantages-inner row justify-content-between align-items-center align-content-center">
					<div className="mb-4 mb-lg-0 col-md-6 col-lg-4">
						<img className="mb-3 mb-md-4" src={easyToStart} alt="" />
						<h3 className="mb-2 mb-md-3">Быстро начать</h3>
						<p>Первоначальная настройка занимает всего 5 минут, а после — добавьте как минимум одного специалиста и одну услугу</p>
					</div>
					<div className="mb-4 mb-lg-0 col-md-6 col-lg-4">
						<img className="mb-3 mb-md-4" src={easyToUse} alt="" />
						<h3 className="mb-2 mb-md-3">Легко использовать</h3>
						<p>Добавляйте, изменяйте, отслеживайте и выгружайте информацию о специалистах, услугах, бронированиях и расписании</p>
					</div>
					<div className="col-md-6 col-lg-4">
						<img className="mb-3 mb-md-4" src={interestingToImprove} alt="" />
						<h3 className="mb-2 mb-md-3">Интересно улучшать</h3>
						<p>Изменяйте дизайн страниц бронирования в визуальном конструкторе, добавляйте новые виджеты и подключайте известные сервисы</p>
					</div>
				</div>
			</div>
			</OnScrollAnimation>

			<OnScrollAnimation>
			<div className="admin-dashboard container py-5 py-lg-8">
				<div className="row justify-content-between align-items-center align-content-center">
					<div className="col-lg-6 order-md-1 order-lg-0">
						<h2 className="mb-4 mb-md-5">Все инструменты для управления бизнесом в удобной админ-панели</h2>
						<p className="mb-3 mb-md-4">Ведите журнал записи ваших клиентов, редактируйте расписание сотрудников на любом устройстве, следите за их нагрузкой и количеством свободных сеансов. У каждого сотрудника будет доступ к его личному расписанию, которое обновляется мгновенно</p>
						<Link to="/tariffs" className="btn btn-outline-secondary">Посмотреть тарифы</Link>
					</div>
					<div className="mt-4 mt-md-0 col-md-8 mb-md-5 mb-lg-0 col-lg-5">
						<img className="w-100" src={adminDashboard} alt="easy2book сервис" />
					</div>
				</div>
			</div>
			</OnScrollAnimation>

			<OnScrollAnimation>
			<div className="opportunities container py-6" id="opportunities">
				<div className="row mb-3">
					<h2 className="col-12 text-center">Возможности</h2>
				</div>
				<div className="row justify-content-between mt-2">
					{opportunities.map((item, key) => 
						<div key={key} className="opportunities-item col-md-6 col-xl-4 d-flex align-items-start my-2 my-md-3">
							<img src={item.icon} className="opportunities-item__icon mr-2" alt="" />
							<div>
								<h4 className="mb-2">{item.heading}</h4>
								<p>{item.text}</p>
							</div>
						</div>
					)}
				</div>
			</div>
			</OnScrollAnimation>

			<OnScrollAnimation>
			<div className="tariffs-for-your-business container py-5 py-lg-8">
				<div className="row justify-content-between align-items-center align-content-center">
					<div className="col-lg-5">
						<h2 className="mb-3 mb-md-4">Тарифы для бизнеса любого размера</h2>
						<p className="mb-3 mb-md-4">Без скрытых платежей или комиссий за транзакции</p>
						<Link to="/tariffs" className="btn btn-primary">Узнать подробнее</Link>
					</div>
					<div className="areas mt-4 mt-md-5 mt-lg-0 col-lg-7">
						<Swiper
							spaceBetween={20}
							slidesPerView={1.2}
							loop={true}
							autoplay={{delay: 2000}}
							onSwiper={(swiper) => console.log(swiper)}
							breakpoints={{576: {}, 768: {slidesPerView: 1.8}, 992: {slidesPerView: 1.2}, 1200: {slidesPerView: 1.7, spaceBetween: 40}}}
						>
							{areas.map((item, key) => 
								<SwiperSlide key={key}>
									<div className="areas-item">
										<img src={item.image} className="w-100" alt={item.heading} />
										<h4 className="areas-item__heading mb-3 ml-3">{item.heading}</h4>
									</div>
								</SwiperSlide>
							)}
						</Swiper>
					</div>
				</div>
			</div>
			</OnScrollAnimation>

		</div>
	);
}

export default Home;

//style={{background: "url("+areasBeauty+")", backgroundSize: "cover"}}