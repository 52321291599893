import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import Input from '../../atoms/Input/Input';
import InputPhone from '../../atoms/InputPhone/InputPhone';

const FormStepUser = (props) => {
	/*
	props.onSubmit
	*/

	let initialValues = { fio: '', position: '', phone: '' };

	let onSubmit = (values, { setSubmitting }) => {
		setSubmitting(false);
		props.onSubmit(1, values);
	}

	let validationSchema = Yup.object({
		fio: Yup.string()
			.min(3, 'Слишком коротко для ФИО')
			.required('Обязательно'),
		position: Yup.string().required('Обязательно'),
		phone: Yup.string().transform(function (value, originalvalue) {
			console.log("+"+value.replace(/[^0-9]/g,""));
				return this.isType(value) && value !== null ? "+"+value.replace(/[^0-9]/g,"") : value;
			}).phone(null, true, 'Введите верный номер телефона').required('Обязательно')
	})

	return (
     	<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
       		{({values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting}) => (
       			<Form className="">
					<div className="mb-2">
						<Input label="ФИО *" name="fio" onChange={handleChange} onBlur={handleBlur} value={values.fio} error={errors.fio && touched.fio && errors.fio} />
					</div>
					<div className="mb-2">
						<Input label="Должность *" name="position" onChange={handleChange} onBlur={handleBlur} value={values.position} error={errors.position && touched.position && errors.position} />
					</div>
					<div className="mb-4">
						<InputPhone label="Телефон *" name="phone" onChange={(e) => setFieldValue("phone", e, true)} onBlur={handleBlur} value={values.phone} error={errors.phone && touched.phone && errors.phone} />
					</div>
					<button disabled={isSubmitting} type="submit" className="btn btn-primary w-100">Продолжить</button>
				</Form>
			)}
     	</Formik>
	);
}

export default FormStepUser;