import { Link } from 'react-router-dom';

// graphics
import logotype from './img/logotype.svg';
import twitter from './img/twitter.svg';
import facebook from './img/facebook.svg';

// styles
import './Footer.scss';

const Footer = () => {
	let shareText = "Привлекайте новых клиентов с новым форматом онлайн—записи и с легкостью управляйте бизнесом в сфере услуг через удобную админ-панель. Easy2Book.ru"
	let shareDomain = encodeURI("https://easy2book.ru");

	return (
		<div className="Footer py-5 py-md-6">
			<div className="container">
				<div className="row">
					<nav className="mb-3 mb-md-5 mb-lg-0 col-md-5 col-lg-3 order-lg-1 offset-lg-2">
						<span className="d-block display-4 mb-2 mb-md-3">Навигация</span>
						<ul className="list-unstyled">
							<li className="mb-2"><Link to="/tariffs">Тарифы</Link></li>
							<li className="mb-2"><Link to="/#opportunities">Возможности</Link></li>
							<li className="mb-2"><Link to="/signin">Вход для компаний</Link></li>
							<li><Link to="/signup">Регистрация</Link></li>
						</ul>
					</nav>
					<nav className="mb-3 mb-md-5 mb-lg-0 col-md-5 col-lg-3 order-lg-2">
						<span className="d-block display-4 mb-2 mb-md-3">Компания</span>
						<ul className="list-unstyled">
							<li className="mb-2"><Link to="/contacts">Контакты</Link></li>
							<li><Link to="/agreement">Пользовательское соглашение</Link></li>
						</ul>
					</nav>
					<div className="mb-5 mb-lg-0 col-md-2 col-lg-1 order-lg-3 d-flex flex-md-column align-items-center align-items-md-end">
						<a href={"http://twitter.com/share?text="+shareText} rel="noopener noreferrer" target="_blank" className="d-block mr-2 mr-md-0 mb-md-2"><img src={twitter} alt="" /></a>
						<a href={"https://www.facebook.com/sharer/sharer.php?u="+shareDomain+"&t=Easy2Book"} className="d-block mr-2 mr-md-0 mb-md-2"><img src={facebook} alt="" /></a>
					</div>
					<div className="Footer__about order-lg-0 col-lg-3">
						<img src={logotype} alt="Логотип Easy2Book" className="mb-1 mb-md-2" />
						<p className="mb-3 mb-md-4">Сервис онлайн-записи и автоматизации ваших бизнес-процессов</p>
						<span className="developer">Разработано в <a href="https://amont.studio">Amont.studio</a></span>
						<span className="copyright d-block">© Easy2Book, 2021</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer;