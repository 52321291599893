import { Link } from 'react-router-dom';

// graphics
import logotype from './logotype.svg';

// styles
import './Header.scss';

const Header = () => {
	return (
		<div className="Header">
			<div className="container py-2 py-md-3 py-xxl-4">
				<div className="row align-items-center align-content-center">
					<div className="Header__logo col-6 col-md-3 col-lg-2">
						<Link to="/"><img src={logotype} alt="Логотип Easy2Book" /></Link>
					</div>
					<nav className="d-none d-md-block col-auto">
						<Link to="/tariffs" className="mr-3">Тарифы</Link>
						<Link to="/#opportunities">Возможности</Link>
					</nav>
					<div className="col-6 col-md-auto d-flex justify-content-end flex-grow-1">
						<Link to="/signin" className="btn btn-outline-secondary btn-sm">Вход</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header;